import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Header from "../components/header"
import * as contactStyles from "./contact.module.css"

export default function ErrorPage() {
  return (
    <div className={contactStyles.layout}>
      <SEO title="404 Page Not Found" />
      <Header />
      <div className="container">
        <div>
          <h1>404 PAGE NOT FOUND</h1>

          <p>
            Return to <Link to="/">Home</Link>
          </p>
        </div>
      </div>
    </div>
  )
}
